import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import logo from './createch-logo.png';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import LogRocket from 'logrocket';
LogRocket.init('ixofem/start-hiring-form');

const ClientForm = () => {

  /* OnSubmit for Alert variable */
  const [showAlert, setShowAlert] = useState(false);
  const navigate = useNavigate()

  const handleSubmit = (event) => {
    event.preventDefault();
    
    setShowAlert(true);


    fetch(`https://hooks.zapier.com/hooks/catch/16197236/3i2ka5d/`, {
    method: "POST",
    body: JSON.stringify(formData),
    })


    navigate('/Success', {
      state: {
        formData: formData,
      },
    });
  };

    /* Datepicker variables */
    const [selectedDate, setSelectedDate] = useState(null);

    const handleDateChange = (date) => {
        setSelectedDate(date);
        setFormData((prevFormData) => ({
          ...prevFormData,
          "startDate": date,
        }));
    };

    const [selectedItem, setSelectedItem] = useState('');


    /* Dropdown variables */
    const handleDropdownChange = (event) => {
      setSelectedItem(event.target.value);
      handleChange(event);
    };

    const [dollarAmount, setDollarAmount] = useState('');


   /* Dollar limiter variables */
    const handleInputChange = (event) => {
    const inputValue = event.target.value;
    // Remove any non-numeric and non-decimal characters from the input value
    const numericValue = inputValue.replace(/[^0-9.]/g, '');
    setDollarAmount(numericValue);
    handleChange(event);
    };


    /* Form data variables */
    const [formData, setFormData] = useState({
      company: '',
      address: '',
      state: '',
      firstName: '',
      lastName: '',
      companyEmail: '',
      phone:'',
      hiringPosition:'',
      startDate:'',
      length:'Indefinitely',
      numberOfAssociates:'1-5',
      rate:'',
      
    });

    const handleChange = (event) => {
      const { name, value } = event.target;
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    };


    const handleStateChange = (event) => {
      const { name, value } = event.target;
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
        branch: '',
      }));
    };



  return (
    <div>
      <nav className="navbar custom-nav" style={{backgroundColor: "#000000"}}>
        <div className="custom-nav">
          <a className="navbar-brand" href="https://www.partnerspersonnel.com/">
            <img src={logo} alt="Company Logo" height="50" href="https://www.partnerspersonnel.com/"/>
          </a>
        </div>
      </nav>
    <form className="App-header" onSubmit={handleSubmit}>
    <hr/>
    <div className="form-group" style={{fontWeight: "700"}}>
        <label htmlFor="intro1">Leverage the extensive network of Partners Personnel through its specialized division, Createch.</label>
    </div>
    <div className="form-group" style={{marginTop: "4px", marginBottom: "12px", fontSize: "16px"}}>
        <label htmlFor="intro3">Reach out to us today and discover your next creative or tech professional. </label>
    </div>
    <div className="form-group col-md-8 item" >
      <label htmlFor="company">Company</label>
      <label htmlFor="companyStar" style={{color: "red"}}>*</label>
      <input type="text" 
      className="form-control" 
      placeholder="Company name" 
      id="company"
      name="company"
      value={formData.company}
      onChange={handleChange}
      required
      />
    </div>
    <Row className="custom-row">
    <Col md={7} className="item col-sm-5">
    <div className="form-group item">
      <label htmlFor="address">Address</label>
      <label htmlFor="addressStar" style={{color: "red"}}>*</label>
      <input 
      type="text" 
      className="form-control" 
      placeholder="Where's the company located?" 
      id="address"
      name="address"
      value={formData.address}
      onChange={handleChange}
  //    onBlur={handleAddressChange}
      required
      />
    </div>
    </Col>
    <Col md={4} className="row-item col-sm-6">
    <div className="form-group item row-item">
      <label htmlFor="state">State</label>
      <label htmlFor="stateStar" style={{color: "red"}}>*</label>
      <select 
      type="text"
      className="form-control" 
      placeholder="Select a state" 
      id="state"
      name="state"
      value={formData.state}
      onChange={handleStateChange}
      required
      >
        <option value="">Select your state</option>
        <option value="AL">Alabama</option>
        <option value="AK">Alaska</option>
        <option value="AZ">Arizona</option>
        <option value="AR">Arkansas</option>
        <option value="CA">California</option>
        <option value="CO">Colorado</option>
        <option value="CT">Connecticut</option>
        <option value="DE">Delaware</option>
        <option value="FL">Florida</option>
        <option value="GA">Georgia</option>
        <option value="HI">Hawaii</option>
        <option value="ID">Idaho</option>
        <option value="IL">Illinois</option>
        <option value="IN">Indiana</option>
        <option value="IA">Iowa</option>
        <option value="KS">Kansas</option>
        <option value="KY">Kentucky</option>
        <option value="LA">Louisiana</option>
        <option value="ME">Maine</option>
        <option value="MD">Maryland</option>
        <option value="MA">Massachusetts</option>
        <option value="MI">Michigan</option>
        <option value="MN">Minnesota</option>
        <option value="MS">Mississippi</option>
        <option value="MO">Missouri</option>
        <option value="MT">Montana</option>
        <option value="NE">Nebraska</option>
        <option value="NV">Nevada</option>
        <option value="NH">New Hampshire</option>
        <option value="NJ">New Jersey</option>
        <option value="NM">New Mexico</option>
        <option value="NY">New York</option>
        <option value="NC">North Carolina</option>
        <option value="ND">North Dakota</option>
        <option value="OH">Ohio</option>
        <option value="OK">Oklahoma</option>
        <option value="OR">Oregon</option>
        <option value="PA">Pennsylvania</option>
        <option value="RI">Rhode Island</option>
        <option value="SC">South Carolina</option>
        <option value="SD">South Dakota</option>
        <option value="TN">Tennessee</option>
        <option value="TX">Texas</option>
        <option value="UT">Utah</option>
        <option value="VT">Vermont</option>
        <option value="VA">Virginia</option>
        <option value="WA">Washington</option>
        <option value="WV">West Virginia</option>
        <option value="WI">Wisconsin</option>
        <option value="WY">Wyoming</option>

      </select>
    </div>
    </Col>
    </Row>
    <Row className="custom-row">
    <Col md={5} className="item col-sm-5">
    <div className="form-group item">
      <label htmlFor="first">First Name</label>
      <label htmlFor="firstStar" style={{color: "red"}}>*</label>
      <input 
      type="text" 
      className="form-control" 
      placeholder="Enter your first name" 
      id="firstName"
      name="firstName"
      value={formData.firstName}
      onChange={handleChange}
      required
      />
    </div>
    </Col>
    <Col md={6} className="row-item col-sm-6">
    <div className="form-group item row-item">
      <label htmlFor="last">Last Name</label>
      <input 
      type="text"
      className="form-control" 
      placeholder="Enter your last name" 
      id="lastName"
      name="lastName"
      value={formData.lastName}
      onChange={handleChange}
      />
    </div>
    </Col>
    </Row>
    <Row className="custom-row">
    <Col md={5} className="item col-sm-5">
    <div className="form-group  item">
      <label htmlFor="email">Company Email</label>
      <input 
      type="email" 
      className="form-control" 
      placeholder="What's a good company email?" 
      id="companyEmail"
      name="companyEmail"
      value={formData.companyEmail}
      onChange={handleChange}
      />
    </div>
    </Col>
    <Col md={6} className="row-item col-sm-6">
    <div className="form-group item row-item">
      <label htmlFor="phone">Phone</label>
      <label htmlFor="phoneStar" style={{color: "red"}}>*</label>
      <input 
      type="text" 
      className="form-control" 
      placeholder="And a company phone" 
      id="phone"
      name="phone"
      value={formData.phone}
      onChange={handleChange}
      required
      />
    </div>
    </Col>
    </Row>

    <div className="form-group col-md-8 item">
      <label htmlFor="position">Hiring Position</label>
      <input 
      type="text" 
      className="form-control" 
      placeholder="Position to fill" 
      id="hiringPosition"
      name="hiringPosition"
      value={formData.hiringPosition}
      onChange={handleChange}/>
    </div>
    <Row className="custom-row">
    
    <Col md={5} className="item col-sm-5">
    <div className="form-group item datepicker">
      <label htmlFor="datepicker" className="datepicker">Start Date</label>
      <DatePicker
        id="date"
        selected={selectedDate}
        onChange={handleDateChange}
        className="form-control"
        dateFormat="MM/dd/yyyy"
        name="date"
        value={formData.date}
      />
    </div>
    </Col>
    <Col md={6} className="row-item col-sm-6">

    <div className='form-group item row-item'>
        <label htmlFor="length-dropdown">Length of Assignment</label>
        <select
          id="length"
          value={selectedItem}
          onChange={handleDropdownChange}
          className="form-control"
          style={{appearance: "auto"}}
          name="length"
        >
          <option value="">Indefinitely</option>
          <option value="1 week">1 Week</option>
          <option value="1 month">1 month</option>
          <option value="6 months">6 months</option>
          <option value="1 year">1 year</option>
        </select>
      </div>
    </Col>
    </Row>
    <Row className="custom-row">
    <Col md={5} className="item col-sm-5">
    <div className='form-group item'>
        <label htmlFor="associate-dropdown">Number of Associates</label>
        <select
          id="numberOfAssociates"
          value={selectedItem}
          onChange={handleDropdownChange}
          className="form-control"
          style={{appearance: "auto"}}
          name="numberOfAssociates"
        >
          <option value="">1-5</option>
          <option value="6-10">6-10</option>
          <option value="11-15">11-15</option>
          <option value="16-20">16-20</option>
          <option value="20+">20+</option>
        </select>
      </div>
    </Col>
    <Col md={6} className="row-item col-sm-6">
    <div className="form-group item row-item">
    <label htmlFor="rate">Hourly Rate</label>
    <div className="input-group">
    <span className="input-group-text">$</span>
        <input
          type="text"
          className="form-control"
          id="dollarAmount"
          name="rate"
          value={dollarAmount}
          onChange={handleInputChange}
          inputMode="numeric"
          pattern="[0-9]*\.?[0-9]*"
          placeholder="0.00"
        />
    </div>
    </div>
    </Col>
    </Row>
    

    <button type="submit" className="btn btn-primary col-md-4 item button">SUBMIT</button>
    {showAlert && (
        <div className="alert alert-success mt-3 item" role="alert">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chat-right-text-fill" viewBox="0 0 16 16" style={{marginRight: "10px"}}>
  <path d="M16 2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h9.586a1 1 0 0 1 .707.293l2.853 2.853a.5.5 0 0 0 .854-.353V2zM3.5 3h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1 0-1zm0 2.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1 0-1zm0 2.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1z"/>
</svg>
          Form submitted - Your account executive will be in contact shortly.
        </div>
      )}
    <div className="form-group" style={{marginBottom: "52px", fontSize: "14px"}}>
        <label htmlFor="intro">Need to speak with someone? Call us at 973-532-2520.</label>
    </div>
    
    
    </form>
    
    </div>
  );
};

export default ClientForm;