import React from 'react';
import logo from './createch-logo.png';
import CustomCard from './CustomCard';
import { useLocation } from 'react-router-dom';

const Success = () => {

    const location = useLocation();
    const formData = location.state && location.state.formData;

    return (
        <div>
            <nav className="navbar custom-nav" style={{backgroundColor: "#000000"}}>
                <div className="custom-nav">
                    <a className="navbar-brand" href="https://www.partnerspersonnel.com/">
                    <img src={logo} alt="Company Logo" height="50" href="https://www.partnerspersonnel.com/"/>
                    </a>
                </div>
            </nav>
            <div className="App-header">
                {formData && (
                <div className="item">
                <h1 style={{fontWeight:"600"}}>{formData.company}</h1>
                </div>
                )}
                <div className="item">
                    <h3>Thanks for submitting your information</h3>
                </div>
                <div className="item" style={{justifyContent:"center", display:"flex"}}>
                    <h5 className="item" style={{textAlign:"center", fontSize:"1rem", width:"60%", marginBottom:"50px"}}>Your account executive will be in contact soon to complete the following steps</h5>
                </div>
                <CustomCard title="Step 1 (completed)" info="Account setup initiated" color="#06ba63" />
                <CustomCard title="Step 2" info="Client onboarding initiated by account executive" color="#adadad"/>
                <CustomCard title="Step 3" info="Onboarding completed and approved to hire" color="#adadad"/>
                <a className="btn btn-primary col-md-4 item button" href="https://createch.com/" rel="noopener noreferrer">
                    Back to home
                </a>
            </div>
            
            

        </div>
        
    );
};

export default Success;